<template>
<v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="color || 'white'"
        v-on="on"
        :loading="loading"
        small icon
      >
        <v-icon>fas fa-user-lock</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Team Access</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1 color1Text--text" small fab @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-text class="px-0 pb-0">
          <v-window v-model="window" touchless>
            <v-window-item :key="0">
              <v-data-table
                :headers="[
                  {text: 'Description', value: 'description'},
                  {text: 'Valid Until', value: 'validTill'},
                  {text: 'CreatedBy', value: 'createdBy'},
                  {text: '', value: 'actions'}
                ]"
                :items="tokenHistory"
                item-key="id"
                :hide-default-footer="!tokenHistory || tokenHistory.length < 10"
                :loading="loading"
                disable-sort dense
              >
                <template v-slot:[`item.validTill`]="{item}">
                  <div>{{item.validTill | unixToLocalDt}}</div>
                </template>
                <template v-slot:[`item.disabled`]="{item}">
                  <v-icon color="error" v-if="item.disabled">fas fa-check</v-icon>
                </template>
                <template v-slot:[`item.actions`]="{item}">
                  <v-btn color="color3" x-small text fab @click.stop="select(item)">
                    <v-icon>fas fa-caret-right</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-window-item>
            <v-window-item :key="1">
              <div class="px-3 pt-2">
                <div class="text-subtitle-2">Step 1: Create An Access Link</div>
                <v-select
                  :items="['Full Access', 'Roster/Lineup Editor']"
                  v-model="accessLevel"
                  label="Access Level"
                ></v-select>
                <date-time-picker
                  :datetime.sync="dtExpires"
                  label="Exp"
                ></date-time-picker>
              </div>
            </v-window-item>
            <v-window-item :key="2">
              <div class="px-3 pt-2" v-if="selected">
                <div class="text-subtitle-2 pb-2">Step 2: Send Access Link</div>
                <div class="text-subtitle-2 font-weight-bold">Link:</div>
                {{selected.link}}
                <div class="text-subtitle-2 font-weight-bold">Status:</div>
                <span :class="selectedStatus === 'Expired' ? 'error--text' : null">{{selectedStatus}}</span>
                <div class="text-subtitle-2 font-weight-bold">Access:</div>
                {{selected.access}}
                <v-expand-transition>
                  <div v-if="showSend" class="pt-6">
                    <v-text-field
                      label="Enter an email or mobile number"
                      v-model="sendTo"
                      color="color3"
                      autofocus outlined
                    ></v-text-field>
                  </div>
                </v-expand-transition>
                <div class="pt-5 text-center">
                  <v-btn color="color3" small text @click.stop="copy(selected.link)">
                    <v-icon small class="mr-3">fas fa-copy</v-icon> copy link
                  </v-btn>
                  <v-slide-x-transition>
                    <v-btn
                      color="color3"
                      small text
                      @click.stop="showSend = true"
                      :disabled="selectedStatus === 'ExpiredMM'"
                      v-if="!showSend"
                    >
                      <v-icon small class="mr-3">fas fa-paper-plane</v-icon> send link
                    </v-btn>
                  </v-slide-x-transition>
                </div>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid class="py-0">
          <v-row class="pa-0">
            <v-col cols="12" class="pa-0">
              <v-window v-model="window" touchless>
                <v-window-item :key="0">
                  <v-btn
                    color="color3"
                    text
                    @click.stop="window = 1"
                  >Give Access</v-btn>
                </v-window-item>
                <v-window-item :key="1">
                  <div class="justify-space-between d-flex">
                    <v-btn
                      color="color3Text color3--text"
                      small
                      fab
                      @click.stop="window = 0"
                      :disabled="loading"
                    >
                      <v-icon>fas fa-caret-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="success"
                      :disabled="!valid"
                      :loading="loading"
                      @click.stop="create"
                    >
                      Create
                    </v-btn>
                  </div>
                </v-window-item>
                <v-window-item :key="2">
                  <div class="justify-space-between d-flex">
                    <v-btn
                      color="color3Text color3--text"
                      small
                      fab
                      @click.stop="window = 0"
                      :disabled="loading"
                    >
                      <v-icon>fas fa-caret-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="success"
                      :disabled="!validAddress"
                      :loading="loading"
                      @click.stop="send"
                      v-if="showSend"
                    >
                      Send
                    </v-btn>
                  </div>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
          </v-container>
        </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snack"
      timeout="3333"
    >
      The link has been copied to the clipboard
    </v-snackbar>
  </v-dialog>
</template>

<script>
import DateTimePicker from '@/components/Utils/DateTimePicker'
import moment from 'moment'
import CopyToClipboard from '@/helpers/CopyToClipboard'
import ValidUSPhone from '@/helpers/ValidUSPhone'
import ValidEmail from '@/helpers/ValidEmail'
import { mapGetters } from 'vuex'

export default {
  props: ['team', 'color', 'tokenStatus'],
  data () {
    return {
      dialog: false,
      loading: false,
      window: null,
      tokenHistory: [],
      accessLevel: null,
      sendTo: null,
      dtExpires: null,
      selected: null,
      snack: false,
      snackMsg: 'The link has been copied to the clipboard',
      showSend: false
    }
  },
  computed: {
    ...mapGetters(['getTeamToken']),
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    valid () {
      return !!(this.accessLevel && this.dtExpires)
    },
    validAddress () {
      const a = this.sendTo
      return a && (ValidUSPhone(a) || ValidEmail(a))
    },
    selectedStatus () {
      if (!this.selected) return false
      return this.selected.disabled ? 'disabled' : moment().isSameOrBefore(this.selected.validTill) ? 'Active' : 'Expired'
    }
  },
  methods: {
    getTokens () {
      this.loading = true
      this.$VBL.team.tokens.getAll(this.team.id, this.teamToken)
        .then(r => {
          this.tokenHistory = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    create () {
      this.loading = true
      const dto = { teamId: this.team.id, dtExpires: this.dtExpires, type: this.accessLevel }
      this.$VBL.team.tokens.create(this.team.id, this.teamToken, dto)
        .then(r => {
          this.tokenHistory.push(r.data)
          this.select(r.data)
          this.resetCreate()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    send () {
      this.loading = true
      const dto = { token: this.selected.id, address: this.sendTo }
      this.$VBL.team.tokens.send(this.team.id, this.teamToken, dto)
        .then(r => {
          this.snackMsg = 'The access link has been sent'
          this.reset()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    reset () {
      this.window = null
      this.resetCreate()
    },
    resetCreate () {
      this.accessLevel = null
      this.sendTo = null
      this.dtExpires = null
      this.showSend = false
    },
    copy (data) {
      CopyToClipboard(data)
      this.snackMsg = 'The link has been copied to the clipboard'
      this.snack = true
    },
    select (token) {
      this.selected = token
      this.window = 2
    }
  },
  watch: {
    dialog: function (val) {
      val && this.getTokens()
      !val && this.reset()
    },
    window: function (val) {
      if (val === 0) this.showSend = false
    }
  },
  components: {
    DateTimePicker
  }
}
</script>
